import {RoutesModel} from "./models/Routes.model";
import {nameRoutes} from "./nameRoutes";
import Home from "../../components/pages/home/Home";
import Clarity from "../../components/pages/clarity/Clarity";
import SimplicityActivation from "../../components/pages/simplicity_activation/SimplicityActivation";
import SimplicityWork from "../../components/pages/simplicity_work/SimplicityWork";
import Overview from "../../components/pages/overview/Overview";
import Other from "../../components/pages/other/Other";
import React from "react";

const authList: RoutesModel = {
    list: [

        {
            path: "/",
            name: nameRoutes.home,
            title: 'home',
            component: <Home/>,
        },
        {
            path: "/clarity",
            name: nameRoutes.clarity,
            title: 'clarity',
            component: <Clarity/>,
        },
        {
            path: "/simplicity_activation",
            name: nameRoutes.simplicity_activation,
            title: 'simplicity_activation',
            component: <SimplicityActivation/>,
        },
        {
            path: "/simplicity_work",
            name: nameRoutes.simplicity_work,
            title: 'simplicity_work',
            component: <SimplicityWork/>,
        },
        {
            path: "/overview",
            name: nameRoutes.overview,
            title: 'overview',
            component: <Overview/>,
        },
        {
            path: "/other",
            name: nameRoutes.other,
            title: 'other',
            component: <Other/>,
        },

    ],
    redirect: '/'
}

export default authList;
