import React from 'react';
import {Link, NavLink} from "react-router-dom";

const AsideMenu = ({menu}: {
    menu: any[],

}) => {


    return (
        <div className={'left-nav mt-3 pb-3'}>
            <h5><b>Sezione video</b></h5>
            <ul className={'side-nav'}>
                {menu?.map((ele, key) => (
                    <NavLink key={key} to={ele.name} className={(({isActive}: any) => isActive ? "active" : "")}>
                        <li>
                            {ele.label}
                        </li>
                    </NavLink>
                ))}
            </ul>
        </div>
    );
};

export default AsideMenu;
