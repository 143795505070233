import React from 'react';
import AsideHelpVideo from "../../asideMenu/AsideHelpVideo";

const Overview = () => {
    return (
        <div className={'content-layout overview row justify-content-between'}>
            <div className={'col-12 col-lg-7 col-xl-8'}>
                <div className={'row pt-3'}>
                    <div className={'col'}>
                        <h5 className={'fw-bold mb-3'}>Panoramica del sistema</h5>

                        <div className={'d-flex w-100'}>
                            <iframe
                                src="https://player.vimeo.com/video/402119943?h=26b237d756&title=0&byline=0&portrait=0"
                                width="640" height="360" frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'col-auto'}>
            <AsideHelpVideo/>
            </div>
        </div>
    );
};
export default Overview;
