import React from 'react';
import AsideHelpVideo from "../../asideMenu/AsideHelpVideo";
const SimplicityWork = () => {
    return (
        <div className={'content-layout simplicity-work row justify-content-between'}>
            <div className={'col-12 col-lg-7 col-xl-8'}>
                <div className={'row pt-3'}>
                    <div className={'col'}>
                        <h5 className={'fw-bold mb-3'}>Semplicità e rapidità della messa in funzione e collegamento ai
                            ventilatori polmonari</h5>
                        <p>
                            Il <b>collegamento ai ventilatori</b> è semplice grazie ai disposables universali che
                            consentono il collegamento a tutti i ventilatori pomonari. </p>
                        <p>
                            Il posizionamento del campionamento il più vicino possibile al paziente, <b>come previsto
                            dagli
                            schemi di montaggio di INOmax DSIR</b>, riporta un indice molto più preciso della miscela di
                            gas
                            che viene somministrata al paziente, ovvero una eventuale diluizione della FiO2, una reale
                            stima delle parti per milione che vengono somministrate al paziente e, a maggior ragione,
                            una stima più precisa della frazione di NO somministrato che si è trasformato in NO2 e che
                            il paziente sta ricevendo.
                        </p>

                    </div>


                    <div className={'mb-5 mt-3'}>
                        <h6><b>Ventilazione senza umidificatore</b></h6>
                        <div className={'col-12'}>
                            <div className={'d-flex w-100'}>
                                <iframe
                                    src="https://player.vimeo.com/video/402118794?h=ad293e22d8&title=0&byline=0&portrait=0"
                                    width="640" height="360" frameBorder="0"
                                    allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                    <div className={'mb-5'}>
                        <h6><b>Ventilazione con umidificatore</b></h6>
                        <div className={'col-12'}>
                            <div className={'d-flex w-100'}>
                                <iframe
                                    src="https://player.vimeo.com/video/402119096?h=a9d4af6ccb&title=0&byline=0&portrait=0"
                                    width="640" height="360" frameBorder="0"
                                    allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                    <div className={'mb-5'}>
                        <h6><b>Ventilazione alta frequenza</b></h6>
                        <div className={'col-12'}>
                            <div className={'d-flex w-100'}>
                                <iframe
                                    src="https://player.vimeo.com/video/402119330?h=ebfcabe75f&title=0&byline=0&portrait=0"
                                    width="640" height="360" frameBorder="0"
                                    allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'col-auto'}>
                <AsideHelpVideo showDown/>
            </div>
        </div>
    );
};

export default SimplicityWork;
