import React from 'react';
import AsideHelpVideo from "../../asideMenu/AsideHelpVideo";

const Clarity = () => {
    return (
        <div className={'content-layout clarity row justify-content-between'}>
            <div className={'col-12 col-lg-7 col-xl-8'}>
                <div className={'row pt-3'}>
                    <div className={'col'}>
                        <h5 className={'fw-bold mb-3'}>Elevata sicurezza del paziente</h5>
                        <p>
                            La gestione della terapia è semplice, grazie all’ampio display ad alto contrasto che
                            permette all’operatore di tenere sotto controllo quanto necessario per una corretta terapia.
                        </p>
                        <p>
                            Nella <u>zona superiore</u> vengono monitorati in continuo i parametri necessari per una
                            corretta terapia con ossido nitrico, ovvero NO somministrato, NO2, O2.
                        </p>
                        <p>
                            La <u>zona inferiore</u> del display riporta il pannello di controllo per l’inserimento
                            della dose terapeutica di INOmax.
                        </p>
                        <p>
                            Lo schermo di INOmax DSir durante la terapia visualizza lo stato del sistema: quale bombola
                            è aperta, lo stato della bombola (se in esaurimento o meno), il segnale del flusso
                            proveniente dal ventilatore, l’erogazione di NO, il flusso verso il paziente e il flusso
                            verso il campionamento.
                        </p>
                        <p>
                            <u>L’operatore, può, quindi, verificare in ogni momento il corretto funzionamento del
                                device.</u>
                        </p>
                        <p>
                            INOmax DS ha un sistema di allarme che, in caso di un qualunque problema all’erogazione o al
                            monitoraggio, <u>localizza il problema sullo schema di montaggio</u> riportato sullo schermo
                            touch screen, facilitando la risoluzione del problema stesso.
                        </p>
                    </div>
                    <h6><b>Gestione operativa</b></h6>
                    <div className={'col-12'}>
                        <div className={'d-flex w-100'}>
                            <iframe
                                src="https://player.vimeo.com/video/402120770?h=8d0ef16d6a&title=0&byline=0&portrait=0"
                                width="640" height="360" frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'col-auto'}>
            <AsideHelpVideo showDown/>
            </div>
        </div>
    );
};

export default Clarity;

