import React from 'react';
import Home from "../pages/home/Home";
import Overview from "../pages/overview/Overview";
import Clarity from "../pages/clarity/Clarity";
import SimplicityActivation from "../pages/simplicity_activation/SimplicityActivation";
import SimplicityWork from "../pages/simplicity_work/SimplicityWork";
import Other from "../pages/other/Other";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import LoadDynamicRoute from "../../core/routes/LoadDynamicRoute";
import authList from "../../core/routes/authList";

const SwitchComponents = ({}: {}) => {

    /*
        const components: any = {
            home: <Home/>,
            clarity: <Clarity/>,
            simplicity_activation: <SimplicityActivation/>,
            simplicity_work: <SimplicityWork/>,
            overview: <Overview/>,
            other: <Other/>,
        }
    */

    const router = createBrowserRouter([
        {
            path: "/",
            element: <Home/>,
        },
        {
            path: "/clarity",
            element: <Clarity/>,
        },
        {
            path: "/simplicity_activation",
            element: <SimplicityActivation/>,
        },
        {
            path: "/simplicity_work",
            element: <SimplicityWork/>,
        },
        {
            path: "/overview",
            element: <Overview/>,
        },
        {
            path: "/other",
            element: <Other/>,
        },
    ]);

    return (
        <div>
            <LoadDynamicRoute
                listRoutes={authList}
            />

            {/*
                {components[activeComp] ? components[activeComp] : 'Seleziona Pagina'}
            */}
        </div>
    );
};

export default SwitchComponents;
