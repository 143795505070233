import React from 'react';
import img_device from "../../../assets/device.png"

const Home = () => {
    return (
        <div className={'content-layout home row justify-content-between'}>
            <div className={'col-12 col-lg-7 col-xl-8'}>
                <h2>
                    <b>INOmax® DSIR. </b>
                    <span className={'text-primary'}>La nuova generazione di sistemi per la somministrazione iNO.</span>
                </h2>
                <div className={'row pt-3'}>
                    <div className={'col-12 col-md-12 col-lg-12 col-xl-6'}>
                        <h5 className={'text-primary'}>Elevata sicurezza del paziente</h5>
                        <ul>
                            <li>Facile e veloce connessione ai ventilatori</li>
                            <li>Somministrazione della concentrazione di NO desiderata indipendente dal flusso e dalla
                                modalità ventilatoria
                            </li>
                            <li>Adeguamento automatico ai cambiamenti
                                del flusso del ventilatore al fine di evitare picchi di NO durante il trattamento
                            </li>
                            <li>Duplice sistema di back-up in caso di guasti per assicurare la continuità della
                                terapia
                            </li>
                            <li>Sistema di miscelazione NO/O2 integrato INOblender che fornisce da 5 a 80 ppm di NO nel
                                circuito ventilatorio (bagging)
                            </li>
                            <li>Semplice sostituzione delle bombole</li>
                            <li>Nuovo sistema di registrazione che consente
                                di documentare la terapia completa con INOmax
                            </li>
                        </ul>
                    </div>
                    <div className={'col-12 col-md-12 col-lg-12 col-xl-6'}>
                        <img src={img_device} alt="device" className={'img-fluid'}/>
                        <div className={'box-under-image mt-3'}>
                            <b className={'d-block mb-3'}>INOmax® DSIR include:</b>
                            <ul>
                                <li>DSir sistema di somministrazione elettronica</li>
                                <li>Carrello con alloggiamento per due bombole</li>
                                <li>INOblender® (somministrazione manuale)</li>
                                <li>Kit per trasporto intra ed extra ospedaliero</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'col-auto'}>
                <div className={'box-help d-flex flex-column'}>
                    <div className={'item-help-title'}>
                        <b>Numero Verde: 800 600 633</b>
                    </div>
                    <div className={'paragraph-help-title'}>
                        <p>Il nostro Numero verde è dedicato a tutti gli Operatori Sanitari che utilizzano i Sistemi di
                            terapia farmaceutica (INOmax Therapy) e a tutti i Clienti dell'Ospedalità pubblica e
                            privata, per i quali fornisce non solo la puntuale gestione logistica del medicinale, ma
                            anche il supporto di carattere tecnico e/o scientifico eventualmente richiesto.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
