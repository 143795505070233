import React from 'react';
import AsideMenuVideo from "./AsideMenuVideo";

const AsideHelpVideo = ({menu, showDown}: { menu?: any[], showDown?: boolean }) => {
    return (
        <div className={'menu-stiky d-flex flex-column d-none d-lg-block'}>
            <div className={'box-help d-flex flex-column mb-4'}>
                <div className={'item-help-title'}>
                    <b>Numero Verde: 800 600 633</b>
                </div>
            </div>
            {/*<AsideMenuVideo menu={menu}/>*/}
            {showDown && <div className={'box-help d-flex flex-column'}>
                <div className={'d-flex gap-3 bg-light'}>
                    <svg xmlns="http://www.w3.org/2000/svg" style={{width: '24px', fill: '#00a0e1'}}
                         version="1.1" id="Livello_1" x="0px" y="0px" viewBox="0 0 42 42"
                    >
                        <g id="linde/iconNew/chevron-down" stroke="none" stroke-width="1" fill="none"
                           fill-rule="evenodd">
                            <g id="arrow-icon" transform="translate(0.000000, 10.500000)">
                                <g id="Clip-2"></g>
                                <polyline id="Fill-1" fill="#00A0E1" mask="url(#mask-2)"
                                          points="3.356873 -0.000768386405 -0.00340137432 4.20256138 20.963577 21.0000004 42 4.20256138 38.6536146 -0.000768386405 21.0052438 16.7966707 3.356873 -0.000768386405"></polyline>
                            </g>
                        </g>
                    </svg>
                    <a href={`#footer`} className={'text-decoration-none'}>Scorri in basso per visualizzare tutti i
                        video</a>
                </div>
            </div>}
        </div>
    );
};

export default AsideHelpVideo;
