import React from 'react';
import Header from "./components/header/Header";
import AsideMenu from "./components/asideMenu/AsideMenu";
import SwitchComponents from "./components/switchComponents/SwitchComponents";
import Footer from "./components/footer/Footer";
import {nameRoutes} from "./core/routes/nameRoutes";

function App() {
    const menu = [
        {
            label: "Home",
            name: nameRoutes.home,
        },
        {
            label: "Chiarezza ed interazione display",
            name: nameRoutes.clarity,
        },
        {
            label: "Semplicità e rapidità delle operazioni di attivazione, taratura iniziale e spurgo",
            name: nameRoutes.simplicity_activation,
        },
        {
            label: "Semplicità e rapidità della messa in funzione e collegamento ai ventilatori polmonari",
            name: nameRoutes.simplicity_work,
        },
        {
            label: "Panoramica del sistema",
            name: nameRoutes.overview,
        },
        {
            label: "Altri argomenti",
            name: nameRoutes.other,
        },
    ]


    return (

        <div className="App">
            <Header/>
            <main>
                <div className={'container bg-light py-3'}>
                    <div className={'layout row'}>
                        <div className={'col-auto'}>
                            <section className={'menu-stiky'}>
                                <AsideMenu menu={menu}/>
                            </section>
                        </div>
                        <div className={'col'}>
                            <section>
                                <SwitchComponents/>
                            </section>
                        </div>
                    </div>
                </div>
            </main>
            <Footer/>
        </div>
    );
}

export default App;
