import React from 'react';
import AsideMenuVideo from "../../asideMenu/AsideMenuVideo";
import AsideHelpVideo from "../../asideMenu/AsideHelpVideo";

const Other = () => {

    const menu = [
        {
            label: "Trasporto intra ed extra ospedaliero",
            name: 'transport',
        },
        {
            label: "INOblender",
            name: 'blender',
        },
        {
            label: "Collegamento al paziente",
            name: 'link',
        },
        {
            label: "Teoria di funzionamento",
            name: 'working',
        },
    ]

    return (
        <div className={'content-layout other row justify-content-between'}>
            <div className={'col-12 col-lg-7 col-xl-8'}>
                <div className={'row pt-3'}>
                    <div className={'col'}>
                        <h5 className={'fw-bold mb-3'}>Altri argomenti</h5>
                        <div className={'mb-5'} id={'transport'}>
                            <h6><b>Trasporto intra ed extra ospedaliero</b></h6>
                            <div className={'col-12'}>
                                <div className={'d-flex w-100'}>
                                    <iframe
                                        src="https://player.vimeo.com/video/402122966?h=1679232b94&title=0&byline=0&portrait=0"
                                        width="640" height="360" frameBorder="0"
                                        allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>
                        <div className={'mb-5'} id={'blender'}>
                            <h6><b>INOblender</b></h6>
                            <div className={'col-12'}>
                                <div className={'d-flex w-100'}>
                                    <iframe
                                        src="https://player.vimeo.com/video/402123347?h=5ac69ea41e&title=0&byline=0&portrait=0"
                                        width="640" height="360" frameBorder="0"
                                        allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>
                        <div className={'mb-5'} id={'link'}>
                            <h6><b>Collegamento al paziente</b></h6>
                            <div className={'col-12'}>
                                <div className={'d-flex w-100'}>
                                    <iframe
                                        src="https://player.vimeo.com/video/402118422?h=d69205853c&byline=0&portrait=0"
                                        width="640" height="360" frameBorder="0"
                                        allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>
                        <div className={'mb-5'} id={'working'}>
                            <h6><b>Teoria di funzionamento</b></h6>
                            <div className={'col-12'}>
                                <div className={'d-flex w-100'}>
                                    <iframe
                                        src="https://player.vimeo.com/video/402122883?h=0e11e93f10&title=0&byline=0&portrait=0"
                                        width="640" height="360" frameBorder="0"
                                        allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'col-auto'}>
                <AsideHelpVideo showDown/>
            </div>
        </div>
    );
};


export default Other;
