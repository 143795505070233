import React from 'react';
import AsideHelpVideo from "../../asideMenu/AsideHelpVideo";

const SimplicityActivation = () => {
    return (
        <div className={'content-layout simplicity-activation row justify-content-between'}>
            <div className={'col-12 col-lg-7 col-xl-8'}>
                <div className={'row pt-3'}>
                    <div className={'col'}>
                        <h5 className={'fw-bold mb-3'}>Semplicità e rapidità delle operazioni di attivazione, taratura
                            iniziale e spurgo </h5>
                        <p>
                            Le <b>procedure preoperative</b> sono:
                        </p>
                        <ul>
                            <li>semplici, grazie alla disponibilità di kit preassemblati per la procedura di spurgo</li>
                            <li>veloci, il tempo necessario ad eseguirle sono di circa 3 minuti</li>
                            <li>sicure, possono essere effettuate fino a 24 ore prima dell’utilizzo del device sul
                                paziente
                            </li>
                        </ul>
                    </div>
                    <h6><b>Procedure preoperative</b></h6>
                    <div className={'col-12'}>
                        <div className={'d-flex w-100'}>
                            <iframe src="https://player.vimeo.com/video/402117982?h=adddb84b0c&byline=0&portrait=0"
                                    width="640" height="360" frameBorder="0"
                                    allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'col-auto'}>
                <AsideHelpVideo/>
            </div>
        </div>
    );
};

export default SimplicityActivation;
