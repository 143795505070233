import React from 'react';

const Header = () => {
    return (
        <header>
            <div className={'container'}>
                <div className={'title-area'}>
                    <h2>Medicale</h2>
                    <h3>Italia</h3>
                </div>
                <div>
                    <a href="/">
                        <img src="logo.png" alt="logo" className={'logo'}/>
                    </a>
                </div>
            </div>
        </header>
    );
};

export default Header;
