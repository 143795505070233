import React from 'react';

const Footer = () => {
    return (
        <footer id={'footer'}>
            <div className={'container'}>
                <div className={'row'}>
                    <div className="col-12 col-lg-6">

                    </div>
                    <div className="col-12 col-lg-3">
                        <ul>
                            <li>
                                <a href=""></a></li>
                            <li>
                                <a href="http://www.lindemedicale.it/it/footer/termsofuse" target="_blank">Termini di
                                    utilizzo</a></li>
                            <li>
                                <a href="http://www.lindemedicale.it/it/footer/legalnotice" target="_blank">Nota
                                    legale</a></li>
                            <li>
                                <a href="http://www.lindemedicale.it/it/footer/dataprotection" target="_blank">Protezione
                                    dei dati</a></li>
                            <li>
                                <a href=""></a></li>
                        </ul>
                    </div>
                    <div className="col-12 col-lg-3">
                        <ul>
                            <li>
                                © The Linde Group {new Date().getFullYear()}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
